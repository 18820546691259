import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProjectSiteSimple from '../../components/project_site_simple'
import ProjectLinkCard from '../../components/project_link_card'

const Aims5 = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('iiot-title')} language={i18n.language} />
          <ProjectSiteSimple title={`${t('news-aims5-project-title')} (2022-1.2.8-KDT-2023-00004)`} text={t('news-aims5-project-desc')} pic="national-research">
              <ProjectLinkCard title={t('read-more-preparation')} path="/projects/aims5-preparations.pdf" />
          </ProjectSiteSimple>
      </Layout>
  )
}

export default Aims5

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "iiot", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
